import React, { lazy, Suspense } from 'react'; 
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../../state/store';
import { Route, Switch } from "react-router-dom";

// Styling crap
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Components
import PrivateRoute from './privateRoute';
import Initial from './initial.js';
import Login from '../pages/auth/login';
import Template from '../layout/template/template';
import Alerts from "../layout/alerts/alerts";
import Conference from '../pages/conferences/singleConference';
const Conferences = lazy(() => import(/*webpackChunkName: 'conferences'*/'../pages/conferences/conferences'));
const Reservation = lazy(() => import(/*webpackChunkName: 'reservation'*/'../pages/reservation/reservation'));
const Manage = lazy(() => import(/*webpackChunkName: 'manage'*/'../pages/manage/manage'));
const Banner = lazy(() => import(/*webpackChunkName: 'banner'*/'../pages/banner/banner'));
const AccessPointMax = lazy(() => import(/*webpackChunkName: 'accessPointMax'*/'../pages/accesspointmax/accesspointmax'));
const TotpQRCode = lazy(() => import(/*webpackChunkName: 'totpQRCode'*/'../pages/totpqrcode/totpqrcode'));

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      margin: '0 auto',
      width: 600,
      height: 'auto',
      background: '#EAF0F8'
    }
  }));

function App () {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Alerts />
            <Paper className={classes.paper}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path='/' exact={true} component={Login} />
                        <Route path='/login' component={Login} />
                        <Initial>
                            <PrivateRoute path='/reserve'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '325px'}}>Loading...</div>}>
                                        <Reservation />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path='/manage'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '400px'}}>Loading...</div>}>
                                        <Manage />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path='/conferences'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '100px'}}>Loading...</div>}>
                                        <Conferences />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path="/conference/:conferenceId">
                                <Template>
                                    <Conference />
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path='/banner'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '300px'}}>Loading...</div>}>
                                        <Banner />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path='/accesspointmax'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '175px'}}>Loading...</div>}>
                                        <AccessPointMax />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                            <PrivateRoute path='/totpqrcode'>
                                <Template>
                                    <Suspense fallback={<div style={{height: '175px'}}>Loading...</div>}>
                                        <TotpQRCode />
                                    </Suspense>
                                </Template>
                            </PrivateRoute>
                        </Initial>
                    </Switch>
                </ConnectedRouter>
            </Paper>
        </React.Fragment>
    );
}

export default App;



