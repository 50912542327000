import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUserAction, twofaUserVerifyAction, twofaUserCancelAction } from '../../../state/ducks/auth/actions';
import LoginForm from './loginForm';
import TwoFaForm from './twofaForm';

class Login extends Component {

    constructor(props) {
        super(props);
        this._user = null;
    }

    onHandleLogin = (user) => {
        this._user = user;
        this.props.dispatch(loginUserAction(user));
    }

    onHandleTwoFa = (user) => {
        this.props.dispatch(twofaUserVerifyAction(user));
    }

    goBack = () => {
        this.props.dispatch(twofaUserCancelAction());
    }
    render() {
        let { isLoading, needsVerifying, isVerifying } = this.props.auth;
        let isAuthenticated = localStorage.getItem('meetings');

        return (
            <div>
                {isAuthenticated && <Redirect to='conferences' />}
                {needsVerifying && <TwoFaForm cb={this.onHandleTwoFa} loading={isVerifying} goback={this.goBack} user={this._user} />}
                {!needsVerifying && <LoginForm login={this.onHandleLogin} loading={isLoading} />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.authState,
    isSessionExpired: state.isSessionExpired
});

export default connect(mapStateToProps)(Login);
