import React, { useState } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { PrimaryButton } from '../../common/buttons/buttons';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '36px'
    },
    flexible: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    center: {
        textAlign: 'center'
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    bold: {
        fontWeight: 'bold'
    },
    blueText: {
        color: '#1976d2'
    }
}));

export default function TwoFaForm({ cb, user, loading, goback }) {
    const classes = useStyles();
    const [values, setValues] = useState({
        passcode: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const onHandleTwofa = (event) => {
        event.preventDefault();

        const { passcode } = values;

        const data = {
            passcode,
            ...user
        };

        cb(data);

    }

    const goBack = (event) => {
        event.preventDefault();

        goback();
    }
    return (
        <div className={classes.root}>
            <form onSubmit={onHandleTwofa} style={{ width: '100%' }}>
                <Grid container spacing={4} direction="column">
                    <Grid xs item>
                        <Typography className={clsx(classes.center, classes.uppercase, classes.spacing, classes.bold, classes.blueText)} variant="h6" component="h6" color="primary" align="center" gutterBottom>
                            Two-factor authentication
                        </Typography>
                        <Typography className={clsx(classes.center, classes.spacing, classes.bold)} variant="body2" align="center" gutterBottom>
                            Protecting your account is our priority. Please enter 
                            in the verification code below.
                        </Typography>
                    </Grid>
                    <Grid xs item>
                        <FormControl fullWidth className={classes.flexible}>
                            <InputLabel htmlFor="standard-adornment-passcode">Verification Passcode</InputLabel>
                            <Input
                                id="standard-passcode"
                                value={values.passcode}
                                autoComplete="one-time-code"
                                maxLength={6}
                                onChange={handleChange('passcode')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs item>
                        <PrimaryButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            fullWidth
                            style={{ position: 'relative' }}>
                            {loading && <AutorenewSharpIcon className="spinner" style={{ position: 'absolute', right: '310px' }}></AutorenewSharpIcon>}
                            Verify
                        </PrimaryButton>
                    </Grid>
                    <Grid xs item className={classes.center}>
                        <Link href="#" onClick={goBack} className={clsx(classes.blueText)}>
                            Cancel and try again later
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

TwoFaForm.defaultProps = {
    user: {},
    loading: false
};

TwoFaForm.propTypes = {
    cb: PropTypes.func.isRequired,
    goback: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};
