import React, { useState } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { PrimaryButton } from '../../common/buttons/buttons';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    }
}));

export default function LoginForm({ login, loading }) {
    const classes = useStyles();
    const [values, setValues] = useState({
        username: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onHandleLogin = (event) => {
        event.preventDefault();
        const { username, password } = values;

        const data = {
            email: username, password
        };

        login(data);
    }

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleLogin} style={{ width: '100%' }}>
                <Grid container spacing={3} direction="column">
                    <Grid xs item>
                        <FormControl fullWidth className={classes.root}>
                            <InputLabel htmlFor="standard-adornment-username">Username</InputLabel>
                            <Input
                                id="standard-adornment-username"
                                value={values.username}
                                autoComplete="username"
                                onChange={handleChange('username')}
                                endAdornment={
                                    <InputAdornment position="end" style={{ marginRight: '10px' }}>
                                        <PersonIcon />
                                    </InputAdornment>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs item>
                        <FormControl fullWidth className={classes.root}>
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                autoComplete="password"
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs item>
                        <PrimaryButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            fullWidth
                            style={{ position: 'relative' }}>
                            {loading && <AutorenewSharpIcon className="spinner" style={{ position: 'absolute', right: '310px' }}></AutorenewSharpIcon>}
                            Login
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

LoginForm.defaultProps = {
    loading: false,
    verify: false
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
};