import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from './views/app/errorBoundary';

import configureStore from './state/store';
import App from './views/app/app';
require('./views/common/app.scss');

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);